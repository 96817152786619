import { deleteCookie, getCookie } from '@/helpers/cookie'
import { redirectToAuth } from '@/helpers/redirectHelper'

/**
 * @param {Object} redirectData
 */
export const processGoToAuthorization = (redirectData) => {
  const needAuth = getCookie('needAuth')

  if (needAuth !== null) {
    deleteCookie('needAuth')
  }

  redirectToAuth(redirectData)
}
