import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setAuthAnyFormData(state, { name, value }) {
    state.formData[name] = value
  },

  setAuthAnyToken(state, payload) {
    state.authAnyToken = payload
  },
}
