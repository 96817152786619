<template>
  <LoaderFullScreen />
</template>

<script>
import LoaderFullScreen from '@/components/LoaderFullScreen.vue'
import { authUrl } from '@/helpers/const/authUrl'
import { processGoToAuthorization } from '@/helpers/processGoToAuthorization'
import { ApiAuthorization } from 'ApiRest/Api/Auth/Authorization'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AuthSso',

  components: {
    LoaderFullScreen,
  },

  computed: {
    authCode() {
      return this.$route.query.authCode
    },

    returnUrl() {
      return this.$route.query.returnUrl
    },
  },

  created() {
    this.init()
  },

  methods: {
    redirectToLogin() {
      processGoToAuthorization({
        url: authUrl.login,
        returnUrl: window.location.href,
      })
    },

    async init() {
      try {
        if (this.authCode) {
          const { data } = await ApiAuthorization.post({
            code: this.authCode,
          })

          if (data === null) {
            this.redirectToLogin()
          } else {
            window.location.href = this.returnUrl || window.location.origin
          }
        }
      } catch {
        this.redirectToLogin()
      }
    },
  },
})
</script>
