import { authUrl } from '@/helpers/const/authUrl'
import { processGoToAuthorization } from '@/helpers/processGoToAuthorization'
import { ApiAuthorization } from 'ApiRest/Api/Auth/Authorization'

/**
 * @param {string} returnUrl
 */
export const logout = async (returnUrl = window.location.href) => {
  await ApiAuthorization.delete()

  processGoToAuthorization({
    url: authUrl.login,
    returnUrl,
  })
}
