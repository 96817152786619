import AxiosHelper from '@/helpers/AxiosHelper'
import convertEmptyDataToDefaultValue from '@/helpers/ConvertEmptyDataToDefaultValue'
import UrlHelper from '@/helpers/UrlHelper'
import { authUrl } from '@/helpers/const/authUrl'
import { isShowcaseDomain } from '@/helpers/domain'
import { processGoToAuthorization } from '@/helpers/processGoToAuthorization'
import { redirectToShowcase } from '@/helpers/redirectHelper'
import HttpHeaders from 'Api/const/HttpHeaders'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import Constants from 'Constants'

const createXHeaders = () => ({
  [HttpHeaders.PAGE_URL]: window.location.href,
})

export default class ApiBase extends AxiosHelper {
  constructor() {
    super(
      true,
      createXHeaders,
      (res) => res,
      async (err) => {
        // если 401
        if (err?.response?.status === HttpStatusCodes.Unauthorized) {
          if (isShowcaseDomain) {
            redirectToShowcase()
          } else {
            processGoToAuthorization({
              url: authUrl.login,
              returnUrl: window.location.href,
            })
          }
        }

        // если 403
        if (err?.response?.status === HttpStatusCodes.Forbidden) {
          const { data } = await this._POST('/auth/is-authorized')

          if (data.accountStatus === Constants.accountStatus.INACTIVE) {
            await this._DELETE(`/auth/authorization`)

            processGoToAuthorization({
              url: authUrl.login,
              returnUrl: window.location.href,
            })
          }
        }

        throw err
      },
    )

    this.BASE_URL = `${window.domains.apiUrl}/rest`
  }

  getUrlParams(params) {
    return UrlHelper.param(params)
  }

  /**
   * @protected
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   */
  _GET(url, config = {}) {
    return this.GET(this.BASE_URL + url, config)
  }

  /**
   * @protected
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   */
  _POST(url, data = {}, config = {}) {
    return this.POST(this.BASE_URL + url, data, config)
  }

  /**
   * @protected
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   */
  _PUT(url, data = {}, config = {}) {
    return this.PUT(this.BASE_URL + url, data, config)
  }

  /**
   * @protected
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   */
  _PATCH(url, data = {}, config = {}) {
    return this.PATCH(this.BASE_URL + url, data, config)
  }

  /**
   * @protected
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   */
  _DELETE(url, config = {}) {
    return this.DELETE(this.BASE_URL + url, config)
  }

  /**
   * Для регистрации в Москве = Отсутствует,
   * удаляем Адрес и внутренние поля объекта, кроме поля Type
   * @protected
   * @param {Object} data
   */
  static normalizeMoscowRegistration(data) {
    const moscowRegistration = data?.moscowRegistration

    if (
      moscowRegistration?.type?.id ===
      Constants.moscowRegistrationType.NO_REGISTRATION
    ) {
      Object.keys(moscowRegistration).forEach((field) => {
        // Все поля кроме типа регистрации
        if (field !== 'type') {
          moscowRegistration[field] = null
        }
      })
    }
  }

  /**
   * Remove country from russian passport if other fields are null
   * @protected
   * @param {{country}} passport
   * @param {number|null} citizenship
   * @returns {{}|null}
   */
  static normalizePassport(passport, citizenship = null) {
    const tempPassport = {
      passport,
    }

    convertEmptyDataToDefaultValue(tempPassport)

    const passportData = tempPassport.passport

    if (citizenship !== Constants.citizenship.RUSSIAN_FEDERATION) {
      return passportData
    }

    if (!passportData) {
      return passportData
    }

    const keys = Object.keys(passportData)

    keys.splice(keys.indexOf('country'), 1)

    let isPassportEmpty = true

    for (const key of keys) {
      if (passportData[key] !== null && passportData[key] !== '') {
        isPassportEmpty = false
        break
      }
    }

    if (isPassportEmpty) {
      return null
    }

    return passportData
  }
}
