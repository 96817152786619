export const getCookie = function (name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([$?*|{}[\]\\^])/g, '\\$1')}=([^;]*)`),
  )

  return matches ? decodeURIComponent(matches[1]) : null
}

export const setCookie = function (name, value, options = {}) {
  options.path = '/'

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  Object.keys(options).forEach((optionKey) => {
    updatedCookie += `; ${optionKey}`
    const optionValue = options[optionKey]

    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`
    }
  })

  document.cookie = updatedCookie
}

export const deleteCookie = function (name) {
  setCookie(name, '', {
    'max-age': -1,
  })
}
