import { getSearchParam } from '@/helpers/searchParams'

/**
 * Редирект на страницу микросервиса авторизации
 * @param {Object} payload
 * @param {string} payload.url
 * @param {string} payload.returnUrl
 * @param {string} payload.email
 * @param {string} payload.needAuth
 * @param {string} payload.token
 */
export const redirectToAuth = ({
  url,
  returnUrl = '',
  email = '',
  needAuth = 'true',
  token = '',
}) => {
  const redirectUrl = new URL(url)

  if (returnUrl) {
    redirectUrl.searchParams.append('returnUrl', returnUrl)
  }

  if (email) {
    redirectUrl.searchParams.append('email', email)
  }

  if (token) {
    redirectUrl.searchParams.append('token', token)
  }

  redirectUrl.searchParams.append('needAuth', needAuth)

  window.location.href = redirectUrl.href
}

/**
 * Редирект на админку
 * @param {string} path
 */
export const redirectToAdminArea = (path) => {
  window.location.href = path
    ? `//${window.domains.admin}/${path}`
    : `//${window.domains.admin}`
}

/**
 * Редирект на ЛК
 */
export const redirectToUserArea = () => {
  window.location.href = `//${window.domains.main}`
}

/**
 * Редирект на витрину
 * @param {string} path
 */
export const redirectToShowcase = (path) => {
  let resultStr = `//${window.domains.showcase}`

  if (path) {
    resultStr += path
  }

  window.location.href = resultStr
}

/**
 * Редирект на origin
 */
export const redirectToOrigin = () => {
  window.location.href = window.location.origin
}

// TODO: Удалить после перехода на МСА
export const redirectAfterLogin = () => {
  const returnUrl = getSearchParam('returnUrl')

  if (returnUrl !== null) {
    window.location.href = returnUrl
  } else {
    redirectToOrigin()
  }
}
