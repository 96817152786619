export default {
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NoContent: 204,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  NotAcceptable: 406,
  Conflict: 409,
  ImATeapot: 418,
  UnprocessableEntity: 422,
  FailedDependency: 424,
  InternalServerError: 500,
  PayloadTooLarge: 413,
  IblsDebug: 590,
}
