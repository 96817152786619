import ApiBase from 'ApiRest/ApiBase'

export const ApiAuthorization = new (class extends ApiBase {
  /**
   * Login
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/auth/authorization', data)
  }

  /**
   * Logout
   * @returns {Promise}
   */
  delete() {
    return this._DELETE(`/auth/authorization`)
  }
})()
