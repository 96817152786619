import ApiBase from 'ApiRest/ApiBase'

export const ApiAuthAny = new (class extends ApiBase {
  /**
   * @param data
   * @returns {Promise<Object>}
   */
  post(data) {
    return this._POST(`/auth/any/7538/do`, data)
  }
})()
