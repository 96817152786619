const authDomain = process.iblsEnv.IBLS_AUTH_DOMAIN

const getMainDomain = () => {
  switch (window.location.host) {
    case window.domains.mobile:
      return `${window.location.protocol}//${window.domains.mobile}`

    case window.domains.showcase:
      return `${window.location.protocol}//${window.domains.showcase}`

    default:
      return `${window.location.protocol}//${window.domains.main}`
  }
}

const mainDomain = getMainDomain()
const authUrlBase = authDomain || mainDomain

export const authUrl = {
  login: `${authUrlBase}/login`,
  logout: `${authUrlBase}/logout`,
  recover: `${authUrlBase}/recover`,
  authByToken: `${authUrlBase}/auth-by-token`,
  authAny: `${authUrlBase}/auth/any/7538`,
}
